import type { EdgeOptions, NodeOptions, BrowserOptions } from '@sentry/nextjs';

const SharedSentryOptions: BrowserOptions | NodeOptions | EdgeOptions = {
    beforeBreadcrumb(breadcrumb) {
        const maxSize = 5000; // 5000 characters limit
        if (breadcrumb?.message && breadcrumb.message?.length > maxSize) {
            breadcrumb.message = breadcrumb.message.slice(0, maxSize);
            breadcrumb.message += '\n [... Truncated to 5000 characters]';
        }
        return breadcrumb;
    },
    beforeSend(event) {
        const maxSize = 5000; // 5000 characters limit
        let message = event?.message || '';

        if (event?.exception?.values?.length) {
            event.exception.values.forEach(exception => {
                if (exception.value && exception.value.length > maxSize) {
                    let exceptionMessage = exception.value;
                    exceptionMessage = exceptionMessage.slice(0, maxSize);
                    exceptionMessage += '\n [... Truncated to 5000 characters]';
                    exception.value = exceptionMessage;
                }
            });
        }

        // Check if the message exceeds max size
        if (message.length > maxSize) {
            message = message.slice(0, maxSize);
            message += '\n [... Truncated to 5000 characters]';
            event.message = message;
        }

        // Returning modified event
        return event;
    },
};

export default SharedSentryOptions;
